/*** REACT ***/
import React from "react";
import { Link } from "gatsby";

/*** COMPONENTS ***/
import Main from "../components/main";
import SEO from "../components/seo";
import "../styles/index.css";

/*** CONSTANTS ***/
import * as ROUTES from "../constants/routes";

/*** IMAGES ***/
import PreviewDbd from "../gatsby_images/image-previewDBD";
import PreviewChatbot from "../gatsby_images/image-previewMjau";
import PreviewTetris from "../gatsby_images/image-previewTetris";
import PreviewPostIt from "../gatsby_images/image-previewPostIt";
import PreviewPubg from "../gatsby_images/image-previewPUBG";
import PreviewBildRep from "../gatsby_images/image-previewBildRep";
import PreviewNetworkSecurity from "../gatsby_images/image-previewNetworkSequrity";
import PreviewProceduralPlanet from "../gatsby_images/image-previewProcPlanet";
import PreviewOpenSpace from "../gatsby_images/image-previewOpenspace";
import PreviewGDK from "../gatsby_images/image-previewGDK";
import PreviewVRStranded from "../gatsby_images/image-previewVRStranded";
import PreviewBildteknik from "../gatsby_images/image-previewBildteknik";
import PreviewRollercoasterTycoon from "../gatsby_images/image-PreviewRollerCoaster";
import PreviewPortfolio from "../gatsby_images/image-previewPortfolio";

const IndexPage = () => (
  <Main>
    <SEO title="Projects" />

    <div class="projects-cards-container">
      <div className="projects-card" style={{ "--animation-order": 4 }}>
        <PreviewPortfolio />
        <Link class="projects-link-style" to={ROUTES.PORTFOLIO} />
        <div class="projects-circle">
          <p class="projects-header-text-black">JOHANNA GRANSTRÖM</p>
          <p class="projects-header-text-grey">MY PORTFOLIO</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 1 }}>
        <PreviewOpenSpace />
        <Link class="projects-link-style" to={ROUTES.OPENSPACE} />
        <div class="projects-circle">
          <p class="projects-header-text-black">OPENSPACE</p>
          <p class="projects-header-text-grey">MASTER THESIS</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 6 }}>
        <PreviewChatbot />
        <Link class="projects-link-style" to={ROUTES.CHATBOT} />
        <div class="projects-circle">
          <p class="projects-header-text-black">CHATBOT</p>
          <p class="projects-header-text-grey">MACHINE LEARNING</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 3 }}>
        <PreviewProceduralPlanet />
        <Link class="projects-link-style" to={ROUTES.PROCEDURAL} />
        <div class="projects-circle">
          <p class="projects-header-text-black">CARTOON PLANET</p>
          <p class="projects-header-text-grey">PROCEDURAL METHODS</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 8 }}>
        <PreviewDbd />
        <Link className="projects-link-style" to={ROUTES.DIEBYDYE} />
        <div class="projects-circle">
          <p class="projects-header-text-black">DIE BY DYE</p>
          <p class="projects-header-text-grey">GAME PROGAMMING </p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 2 }}>
        <PreviewBildRep />
        <Link class="projects-link-style" to={ROUTES.IMAGEREP} />
        <div class="projects-circle">
          <p class="projects-header-text-black">IMAGE INCEPTION</p>
          <p class="projects-header-text-grey">IMAGE REPRODUCTION</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 11 }}>
        <PreviewGDK />
        <Link class="projects-link-style" to={ROUTES.GDK} />
        <div class="projects-circle">
          <p class="projects-header-text-black">POP-UP EVENT</p>
          <p class="projects-header-text-grey">DESIGN</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 7 }}>
        <PreviewTetris />
        <Link class="projects-link-style" to={ROUTES.TETRIS} />
        <div class="projects-circle">
          <p class="projects-header-text-black">TETRIS</p>
          <p class="projects-header-text-grey">AI</p>
        </div>
      </div>

      <div class="projects-card " style={{ "--animation-order": 14 }}>
        <PreviewPostIt />
        <Link class="projects-link-style" to={ROUTES.POSTIT} />
        <div class="projects-circle">
          <p class="projects-header-text-black">POST IT</p>
          <p class="projects-header-text-grey">WEB DEVELOPMENT</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 12 }}>
        <PreviewPubg />
        <Link class="projects-link-style" to={ROUTES.INFOVIZ} />
        <div class="projects-circle">
          <p class="projects-header-text-black">PUBG VIZ</p>
          <p class="projects-header-text-grey">VISUALIZATION</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 9 }}>
        <PreviewNetworkSecurity />
        <Link class="projects-link-style" to={ROUTES.NETWORKSECURITY} />
        <div class="projects-circle">
          <p class="projects-header-text-black">KEYLOGGER</p>
          <p class="projects-header-text-grey">NETWORK SECURITY</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 13 }}>
        <PreviewVRStranded />
        <Link class="projects-link-style" to={ROUTES.VRSTRANDED} />
        <div class="projects-circle">
          <p class="projects-header-text-black">VR STRANDED</p>
          <p class="projects-header-text-grey">BACHELOR'S PROJECT</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 10 }}>
        <PreviewBildteknik />
        <Link class="projects-link-style" to={ROUTES.IMAGETECH} />
        <div class="projects-circle">
          <p class="projects-header-text-black">IMAGES IN SPACE</p>
          <p class="projects-header-text-grey">IMAGE TECHNOLOGY</p>
        </div>
      </div>

      <div class="projects-card" style={{ "--animation-order": 11 }}>
        <PreviewRollercoasterTycoon />
        <Link class="projects-link-style" to={ROUTES.SIMULATION} />
        <div class="projects-circle">
          <p class="projects-header-text-black">ROLLER COASTER</p>
          <p class="projects-header-text-grey">SIMULATION</p>
        </div>
      </div>
    </div>
  </Main>
);

export default IndexPage;
